/**
 * 获取文件后缀名
 * @param fileName 
 * @returns 
 * e.g.  "test.exe"->"exe"
 */
export function fileExt(fileName:string){
	const ret = fileName?.substring(fileName?.lastIndexOf('.') + 1);
	return ret;
}

/**
 * @description: 分割数组
 * @param {any} arr
 * @param {number} chunkSize
 * @return {*}
 * @author: shanbian
 */
export function splitArray(arr:any, chunkSize:number) {
  var result = [];
  for (var i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

export function guid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0,
					v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
	});
}
